// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCd6Wm8zAMD6axhAiF6a_juOE_nps5KRiE",
  authDomain: "bill-e7f52.firebaseapp.com",
  databaseURL: "https://bill-e7f52-default-rtdb.firebaseio.com",
  projectId: "bill-e7f52",
  storageBucket: "bill-e7f52.appspot.com",
  messagingSenderId: "71864798385",
  appId: "1:71864798385:web:0e3d7df690a41aa49f6690",
  measurementId: "G-D2MSVVYL58"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };